<template>
  
        <div class="JYYW">
      <span class="h1">经营业务</span>
      <div class="JYYWTabBg">
        <!-- <div class="tabItem tabItem1">空运出口</div> -->
        <div class="tabItem" :class=" titleArray.indexOf(item)==active?'tabItem1':'tabItem'"  @click="setIndex(titleArray, item)"  v-for="item in titleArray" :key="item" >{{item}}</div>
        
      </div>
      <div class="JYYWItemBox">
        <div class="JYYWItem">
          <img class="itemImg" :src="items[active].srcA" alt="">
          <div class="itemTitle">{{ items[active].titleA }}</div>
          <div class="itemContent">{{ items[active].contentA }}</div>
          <p>{{items[active].adv1}}</p>
          <p> {{items[active].adv2}}</p>
          <p>{{items[active].adv3}}</p>
        </div>
        <div class="JYYWItem">
           <img class="itemImg" :src="items[active].srcB" alt="">
          <div class="itemTitle">{{ items[active].titleB }}</div>
          <div class="itemContent">{{ items[active].contentB }}
          </div>
          <p>{{items[active].advD}}</p>
          <p> {{items[active].advE}}</p>
          <p>{{items[active].advF}}</p>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: { titleArray: Array, items: Array },
   data() {
    return {
      active: 0,
      activeName: "one",
    };
  },
  methods: {
    setIndex(array, item) {
      this.active = array.indexOf(item);
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>




.JYYW {
  width: 100vw;
  /* background: #DDD; */
  padding-top: 32px;
  padding-bottom: 56px;

  display: flex;
  /* a-content: center; */
  align-items: center;
  flex-direction: column;
}

.h1 {
  width: 128px;
  height: 45px;
  font-family: PingFangSC-Semibold;
  font-size: 32px;
  color: #333333;
  letter-spacing: 0;
  text-align: center;
  font-weight: 600;
  margin-bottom: 36px;
}

.JYYWTabBg {
  width: 100vw;
  /* height: 64px; */
  background: #fff;
  border-bottom: 1px solid rgb(221, 221, 221);

  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.tabItem {
  height: 30px;
  margin-right: 120px;
  cursor: pointer;
  font-family: PingFangSC-Regular;
  font-size: 20px;
  color: #333333;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
}

.JYYWItemBox p {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #666;

  letter-spacing: 0;

  line-height: 24px;
  margin-left: 40px;
  margin-bottom: 4px;
  position: relative;
}

.JYYWItemBox p::before {
  content: '';
  width: 6px;
  height: 6px;
  background: #F1001F;
  position: absolute;
  top: 9px;
  left: -12px;
  border-radius: 50%;
}

.tabItem:last-child {
  margin-right: 0;
}

.tabItem1 {
  border-bottom: 4px solid #F1001F;

}

.JYYWItem {
  width: 600px;
  height: 488px;
  /* background: #AAA; */
}

.JYYWItemBox {
  display: flex;
}

.JYYWItem:first-child {
  margin-right: 32px;
}

.itemImg {
  width: 600px;
  height: 320px;
  background: blue;
}

.itemTitle {
  width: 120px;
  height: 28px;
  font-family: PingFangSC-Semibold;
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
  font-weight: 600;

  margin-top: 24px;
  margin-left: 24px;
  margin-bottom: 8px;
}

.itemContent {
  width: 552px;
  height: 44px;
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #666666;
  letter-spacing: 0;
  font-weight: 400;
  margin-left: 24px;
  margin-bottom: 16px;
}

.itemIcon {
  width: 24px;
  height: 24px;
  background: #F1001F;

  margin-left: 24px;
}


</style>
